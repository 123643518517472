<template>
    <LayoutWithNav
        ><BackOnTop :url="url" />
        <h2>美容集點</h2>
        <div class="info_wrappper">
            <Tab
                :tabList="tabList"
                :currentTab="currentTab"
                @update="tabHandler"
            />
            <div class="unget_list list" v-show="currentTab === 'card'">
                <PointCardItem
                    v-for="(el, idx) in pointCardList"
                    :showPopup="idx >= 1"
                    :togglePopup="togglePopup"
                    :key="idx"
                    :info="el"
                ></PointCardItem>
                <h3 style="color:#FF9F48;">活動辦法</h3>
                <p v-html="content" style="text-align:left;" class="f_black" />
            </div>
            <ul class="msg_list" v-show="currentTab === 'record'">
                <li class="d_flex" v-for="(el, idx) in msgList" :key="idx">
                    <img
                        src="../../assets/images/point_delete.png"
                        style="width:30px;"
                        alt=""
                        v-if="el.title === '點數過期'"
                    />
                    <img
                        src="../../assets/images/ticket.png"
                        alt=""
                        style="width:30px;"
                        v-else-if="el.title === '兌換票券'"
                    />
                    <img
                        src="../../assets/images/foot_active_30.png"
                        alt=""
                        style="width:30px;"
                        v-else-if="el.title === '獲得點數'"
                    />
                    <img
                        src="../../assets/images/close.png"
                        alt=""
                        style="width:30px;"
                        v-else
                    />
                    <div class="detail f_black">
                        <p>{{ el.title }}：{{ el.value }}</p>
                        <font>{{ el.time }}</font>
                        <font>{{ el.detail }}</font>
                    </div>
                </li>
            </ul>
        </div>
        <PopupCardExchange  v-model="showPopupCardExchange" :previous_link="previous_link" :now_link="now_link" :point="point" />
    </LayoutWithNav>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import LayoutWithNav from '@/layouts/LayoutWithNav.vue';
import Tab from '@/components/Tab.vue';
import PointCardItem from '@/components/PointCardItem.vue';
import PopupCardExchange from '@/components/Popup/PopupCardExchange.vue';
import { getRewardActivity, showRewardCard, pointRecord } from '@/apis/api.js';
export default {
    name: 'PointIndex',
    components: { BackOnTop, LayoutWithNav, Tab, PointCardItem, PopupCardExchange },
    data: () => ({
        showPopupCardExchange: false,
        msgList: [
            {
                title:"點數過期",
                value:-3,
                time:"2023/05/04",
                detail:"您的點數已到期失效"
            },
            {
                title:"兌換票券",
                value:-20,
                time:"2023/05/04",
                detail:"兌換優惠券一張"
            },
            {
                title:"點數作廢",
                value:-17,
                time:"2023/05/04",
                detail:"消費項目：剪毛、洗澡"
            },
            {
                title:"獲得點數",
                value:17,
                time:"2023/05/04",
                detail:"消費項目：剪毛、洗澡"
            },
        ],
        tabList: [
            {
                name: '集點卡',
                value: 'card',
            },
            {
                name: '集點歷程',
                value: 'record',
            },
        ],
        currentTab: 'card',
        pointCardList: [],
        couponUndoneList: [],
        couponDoneList: [],
        url:'/',
        previous_link:'',
        now_link:'',
        point:0,
        content:''
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    created() {
        if (
            !localStorage.getItem('phone') ||
            !localStorage.getItem('password') ||
            !localStorage.getItem('member_no')
        ){
            this.$router.push('/login');
        }
    },
    mounted() {
        const parent = this;
        this.getRewardCardList().then(function(){
            parent.$store.state.isLoading = false;
        })
        getRewardActivity(localStorage.getItem('phone'), localStorage.getItem('password')).then(function(rsp){
            parent.content = JSON.parse(rsp).content;
        })
        this.getPointRecord();
    },
    methods: {
        togglePopup(){
            this.showPopupCardExchange = !this.showPopupCardExchange;
        },
        getPointRecord(){
            const parent = this;

            return new Promise(function(resolve){
                pointRecord(localStorage.getItem('phone'), localStorage.getItem('password')).then(function(rsp){
                    rsp = JSON.parse(rsp);
                    let filter_date = new Date().getTime() - 1000*60*60*24*240
                    rsp = rsp.filter(function(obj){
                        var date = new Date(`${obj.date.slice(0,4)}/${obj.date.slice(4,6)}/${obj.date.slice(6,8)}`).getTime()
                        return date >= filter_date;
                    })
                    rsp = rsp.sort(function(a,b){
                        return b.date - a.date
                    })
                    parent.msgList = [];
                    for(let i in rsp){
                        parent.msgList.push({
                            title:rsp[i].caption,
                            value:rsp[i].point,
                            time:`${rsp[i].date.slice(0,4)}/${rsp[i].date.slice(4,6)}/${rsp[i].date.slice(6,8)}`,
                            detail:rsp[i].content.match("兌換") ? '兌換優惠券一張' : rsp[i].content
                        })
                    }
                    resolve();
                })
            })
        },
        getRewardCardList(){
            const parent = this;
            return new Promise(function(resolve){
                showRewardCard(localStorage.getItem('phone'), localStorage.getItem('password')).then(function(rsp){
                    rsp = JSON.parse(rsp);
                    rsp = rsp.sort(function(a,b){
                        return a.e_date - b.e_date;
                    })
                    console.log("showRewardCard" ,rsp);
                    for(let i in rsp){
                        
                        let card_info = rsp[i];
                        if(rsp.length == 1){
                            card_info.type = 'card';
                            parent.now_link = `/point/coupon?id=${card_info.id}&point=${card_info.rm_point}`;

                        } else if(rsp.length > 1){
                            if(i == 0) {
                                // 舊的卡片 orange
                                card_info.type = 'record';
                                parent.previous_link = `/point/coupon?id=${card_info.id}&point=${card_info.rm_point}`;
                                parent.point = card_info.rm_point;
                            }
                            else if(i == 1) {
                                card_info.type = 'card';
                                parent.now_link = `/point/coupon?id=${card_info.id}&point=${card_info.rm_point}`;

                            }
                        }
                        parent.pointCardList.push(card_info);
                    }
                    resolve()
                })
            })
        },
        tabHandler(value) {
            this.currentTab = value;
        },
    },
};
</script>
<style lang="scss" scoped>
h2 {
    color: $color_main_first;
    font-size: 26px;
    font-weight: bold;
    padding: 12px 16px;
    line-height: 1;
    text-align: left;
}
h3 {
    color: $color_main_first;
    font-size: 22px;
    font-weight: bold;
    padding: 12px 0px;
    line-height: 1;
    text-align: left;
}
.list {
    margin-top: 12px;
    padding: 0 16px 70px;
}
.msg_list {
    margin-top: 12px;
    padding: 0 24px 70px;
    list-style: none;

    li {
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;
        border-bottom: 1px solid #e6e6e6;
        .detail {
            text-align: left;
            width: calc(100% - 60px);
            p,
            font {
                margin-bottom: 8px;
                font-size: 14px;
                display: block;
                line-height: 1.4;
            }
            p {
                font-size: 16px;
            }
        }
    }
}
</style>
